<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <v-col sm="6" cols="12" class="py-0">
                  <h2 class="mb-0" style="font-size: 25px; font-weight: 550">
                    {{ this.$route.params.name }}
                  </h2>
                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.show"
                    right
                  >
                    {{ snackbar.message }}
                  </v-snackbar>
                </v-col>

                <v-col sm="6" cols="12" class="py-0">
                  <v-btn
                    mdi-check-circle-outline
                    class="mx-2 float-right"
                    :color="getColorStatus(approval)"
                    @click.prevent="validate()"
                    dark
                    depressed
                    v-if="approval == 0"
                    ><v-icon left> mdi-check-circle-outline </v-icon
                    >Approve</v-btn
                  >

                  <v-btn
                    class="mx-2 float-right"
                    depressed
                    :color="getColorStatus(approval)"
                    v-else-if="approval == 1"
                    @click.prevent="validate()"
                    dark
                  >
                    <v-icon left> mdi-close-circle-outline </v-icon>Reject
                    Approve</v-btn
                  >
                  <download-excel
                    :data="listScoreData"
                    :fields="exportFields"
                    name="subject_scores.xls"
                  >
                    <v-btn
                      class="mx-2 float-right rounded-pill"
                      color="primary"
                      @click="filterStudent()"
                      ><h5>
                        Export Score
                        <i class="fa fa-file-excel fa-lg"></i>
                      </h5>
                    </v-btn>
                  </download-excel>
                </v-col>
              </v-row>
              <div style="margin-top: 20px"></div>
              <v-breadcrumbs :items="items" :href="items.href">
                <template v-slot:divider>
                  <v-icon>mdi-chevron-right</v-icon>
                </template>
              </v-breadcrumbs>
              <v-col sm="6" cols="12" md class="text-center mb-8">
                <div class="text-h5">
                  Result of Subject {{ this.$route.params.name }}
                </div>
              </v-col>
              <v-row justify="center">
                <template>
                  <vue-excel-editor
                    v-model="listScore"
                    readonly
                    no-header-edit
                    autocomplete
                    filter-row
                    enterToSouth
                    ref="grid"
                  >
                    <vue-excel-column
                      field="stu_id"
                      label="Student ID"
                      readonly
                      type="string"
                      width="150px"
                      key-field
                    />
                    <vue-excel-column
                      field="id_student"
                      label="ID"
                      readonly
                      type="string"
                      :invisible="true"
                      width="150px"
                      key-field
                    />
                    <vue-excel-column
                      field="name_en"
                      readonly
                      label="Name EN"
                      key-field
                      type="string"
                      width="200px"
                    />

                    <vue-excel-column
                      field="name_kh"
                      readonly
                      label="Name Khmer"
                      key-field
                      type="string"
                      width="200px"
                    />
                    <vue-excel-column
                      field="Attendance"
                      key-field
                      label="Attendance"
                      type="number"
                      width="190px"
                    />
                    <vue-excel-column
                      field="Midterm"
                      key-field
                      label="Midterm"
                      type="number"
                      width="190px"
                    />
                    <vue-excel-column
                      field="Final"
                      key-field
                      label="Final"
                      type="number"
                      width="190px"
                    />
                    <vue-excel-column
                      field="total_score"
                      readonly
                      key-field
                      text-align="center"
                      label="TOTAL"
                      type="number"
                      width="190px"
                    />
                    <vue-excel-column
                      field="Grade"
                      readonly
                      key-field
                      text-align="center"
                      sticky
                      label="Grade"
                      type="number"
                      width="210px"
                    />
                  </vue-excel-editor>
                </template>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>

<script>
import {
  getStudentSubjectScoreBySubjectId,
  validateSubjectScore,
} from "@schoolbase/web-client-lib";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import store from "@/store";
export default {
  name: "app",
  props: ["fullPage", "alertMessage", "color", "alertText"],
  data() {
    return {
      id: "",
      listScore: [],
      isLoading: false,
      myLoading: false,
      token: store.getters.getToken,
      name_en: "",
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      approval: "",
      rowID: "",
      stu_id: "",
      name_kh: "",
      listScoreData: [],
      listStudent: [],
      items: [
        {
          text: "Generation",
          disabled: false,
          href: "javascript:history.go(-1)",
        },
        {
          text: "Session",
          disabled: false,
          href: "javascript:history.go(-1)",
        },
        {
          text: "Subject",
          disabled: false,
          href: "javascript:history.go(-1)",
        },
        {
          text: "Score",
          disabled: true,
        },
      ],
      listHeader: [
        { text: "Student ID", value: "stu_id" },
        { text: "Name EN", value: "name_en" },
        { text: "Name KH", value: "name_kh" },
        { text: "Attendance", value: "Attendance" },
        { text: "Midterm", value: "Midterm" },
        { text: "Final", value: "Final" },
        { text: "Total Score", value: "total_score" },
        { text: "Grade", value: "Grade" },
      ],
      exportFields: {},
    };
  },
  components: {
    Loading,
  },
  computed: {},
  methods: {
    filterStudent() {
      if (this.listScoreData) {
        for (let header of this.listHeader) {
          if (this.listScoreData[0].hasOwnProperty(header.value)) {
            this.exportFields[header.text] = header.value;
          }
        }
      }
    },
    getColorStatus(item) {
      if (item === 0) {
        return "green";
      } else if (item === 1) {
        return "red";
      }
    },
    async validate() {
      try {
        await validateSubjectScore(this.$route.params.id);
        this.loadScore();
      } catch (e) {
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
    async loadScore() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const APIResponse = await getStudentSubjectScoreBySubjectId(
          this.$route.params.id,
          this.token
        );
        this.listScore = APIResponse.payload.students;
        this.listScoreData = APIResponse.payload.students;
        this.approval = APIResponse.payload.approval;
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }

        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
  },
  async mounted() {
    this.loadScore();
  },
};
</script>
